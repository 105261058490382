import { useContext } from "react";
import { LanguageContext } from "./LanguageProvider";
import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";

const linkStyle = (enabled) => ({
      fontWeight: (enabled ? 700 : 'inherit'),
      textDecoration: (enabled ? 'bold' : 'inherit'),
      cursor: enabled ? 'default' : 'pointer',
      color: 'white',
});

const LangSwitch = (props) => {
    const { language, setLanguage } = useContext(LanguageContext);

    return (
        <Box sx={{ display: 'inline-block', color: 'white' }}>
            <Link role='button' onClick={() => setLanguage('de')}
                sx={linkStyle(language === 'de')}
                variant="langSwitchLink">DE</Link><Typography variant="langSwitchLink" px={1}>|</Typography>
            <Link role='button' onClick={() => setLanguage('en')}
                sx={linkStyle(language === 'en')}
                variant="langSwitchLink">EN</Link>
        </Box>
    )
}

export default LangSwitch