import React, { } from 'react';
import {

  Button,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { useLocalizedResource } from '../components/useLocalizedResource';
import { RestartAlt } from '@mui/icons-material';

export default function FinishedThankYou({ setAppState, startOver }) {


  const handleButtonClick = () => {
    setAppState(appState => ({
      ...appState,
      navigatedToWebsiteAt: new Date().toUTCString()
    }));
    window.location.href = sd.backToWebsiteUrl;
  };

  const sd = useLocalizedResource("screen-8-finished-thank-you");
  if (sd == null) return null;

  return (
    <Container>
      <Grid container direction={'column'} alignItems='center' sx={{ width: '100%' }} pb={12}>

        <Grid item>
          <img src={'images/danke.png'} alt=''></img>
        </Grid>
        <Grid item>
          <Typography variant='h4' dangerouslySetInnerHTML={{ __html: sd.title }} gutterBottom textAlign='center' xs={1}></Typography>
        </Grid>
        <Grid item>
          <Typography variant='h5' dangerouslySetInnerHTML={{ __html: sd.subtitle }} mb={4} textAlign='center'></Typography>
        </Grid>
        <Grid item container justifyContent='center' gap={4}>
          <Button
            onClick={handleButtonClick}
            variant="contained"
            size='large'>{sd.backToWebsite}</Button>

          <Button
            onClick={() => startOver()}
            variant="outlined"
            startIcon={<RestartAlt/>}
            size='large'>{sd.startOver}</Button>
        </Grid>
      </Grid>
    </Container>
  );
}
