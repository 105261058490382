import { forwardRef } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, LinearProgress, Container, Grid } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

import { useLocalizedResource } from "./useLocalizedResource";
import { useTheme } from '@emotion/react';
import LangSwitch from './LangSwitch';

const CustomAppBar = forwardRef(({ logo, goBack, currentScreenIndex, progress, screens }, ref) => {

    const theme = useTheme();
    const gd = useLocalizedResource("global");
    if (gd == null) return null;

    return (
        <AppBar ref={ref} position="sticky" color="default" elevation={0} sx={{ backgroundColor: '#263238', paddingTop: '16px', paddingBottom: '12px' }}>
            <Container>
                <Grid item >
                    <Toolbar disableGutters={true} sx={{
                        [theme.breakpoints.down('sm')]: {
                            flexDirection: 'column-reverse',
                            alignItems: 'flex-start',
                        },
                    }} >
                        {/* Title */}
                        <Typography variant="h4" component="div" sx={{
                            flexGrow: 1,
                            color: 'white',
                        }}>
                            {gd.title}
                        </Typography>
                        <Box sx={{
                            alignSelf: 'stretch',
                            display: 'flex',
                            flexGrow: 'auto',
                            columnGap: '1rem',
                            justifyContent: {
                                xs: 'space-between'
                            },
                            justifyItems: 'stretch',
                            alignItems: {
                                xs: 'normal',
                                sm: 'center',
                            },
                            marginBottom: {
                                xs: '0.5rem',
                                sm: 0
                            },
                            flexDirection: {
                                xs: 'row-reverse',
                                sm: 'row',
                            },
                        }}>
                            <LangSwitch />
                            {/* Logo */}
                            <a href="https://fluidmobile.de?utm_source=dtx_configurator" target="_blank"  rel="noreferrer" title='Open Company Website in new tab'>
                                <Box sx={{
                                    flexGrow: 0,
                                    height: '1.8rem',
                                    [theme.breakpoints.down('sm')]: {
                                        height: '1.2rem',
                                    },
                                }}
                                >
                                    <img src={logo} alt="logo" style={{ height: '100%' }} />
                                </Box>
                            </a>
                        </Box>
                    </Toolbar>
                </Grid>
                <Grid item sx={{ marginTop: '20px' }}>
                    <Toolbar disableGutters={true}>
                        {/* Navigation buttons */}
                        <Button size='large' variant="text" startIcon={<ChevronLeft />} onClick={goBack} disabled={currentScreenIndex === 0} sx={{
                            display: (currentScreenIndex === 0 ? 'none' : 'default'),
                            paddingX: 0,
                            marginRight: {
                                xs: 0,
                                sm: 4
                            }
                        }}>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                {gd.button.back}
                            </Box>
                        </Button>
                        {/* Progress Bar */}
                        <LinearProgress variant="determinate" value={progress} color="secondary" sx={{ flexGrow: 1, height: '12px', borderRadius: 3, backgroundColor: '#455A64' }} palette='light' />
                        {(currentScreenIndex + 1 === screens.length) ?
                            (
                                <Typography variant='h6' color='ButtonFace' sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    paddingLeft: {
                                        xs: 2,
                                        sm: 4
                                    }
                                }} >
                                    {gd.label.finished}
                                </Typography>
                            )
                            :
                            (
                                <Typography variant='h6' color='ButtonFace' sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    paddingLeft: {
                                        xs: 2,
                                        sm: 4
                                    }
                                }} >
                                    {gd.label.step} {(currentScreenIndex + 1)} / {screens.length}
                                </Typography>
                            )
                        }

                    </Toolbar>
                </Grid>
            </Container>
        </AppBar>
    )
});

export default CustomAppBar;