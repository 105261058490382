import React, { useState } from 'react';
import {
  Container, Typography, Grid, Box, Button, Divider, List, ListItem, Checkbox, ListItemIcon, ListItemText, Paper, TextField, ListItemButton
} from '@mui/material';

import { ChevronRight } from '@mui/icons-material';
import { useLocalizedResource } from '../components/useLocalizedResource';

function concatenateWords(words, separator, lastSeparator) {
  if (words.length === 0) {
    return '';
  } else if (words.length === 1) {
    return words[0];
  } else {
    return words.slice(0, -1).join(separator) + lastSeparator + words[words.length - 1];
  }
}

function SelectUserCasesScreen({ onCanContinueChanged, appState, setAppState, continueToNextPage }) {
  const [customUseCase, setCustomUseCase] = useState(appState.customUseCase || '');
  const [selectedUseCaseIds, setSelectedUseCaseIds] = useState(appState.selectedUseCaseIds || []);

  const handleCustomUseCaseChange = (event) => {
    const newCustomUseCase = event.target.value || '';
    setCustomUseCase(newCustomUseCase)
  };

  const handleCustomUseCaseBlur = (event) => {
    setAppState(appState => ({
      ...appState,
      customUseCase
    }));
  };

  const handleUseCaseClicked = (useCaseId) => {
    let newList = selectedUseCaseIds;
    const deselectedUseCaseIds = appState.deselectedUseCaseIds || [];
    if (selectedUseCaseIds.includes(useCaseId)) {
      newList = selectedUseCaseIds.filter(id => id !== useCaseId)
      deselectedUseCaseIds.push(useCaseId);
    } else {
      newList = [...newList, useCaseId]
    }

    setSelectedUseCaseIds(newList)

    setAppState(appState => ({
      ...appState,
      selectedUseCaseIds: newList,
      deselectedUseCaseIds: deselectedUseCaseIds,
    }));
  };

  const canContinue = () => {
    return !!customUseCase || !!selectedUseCaseIds.length
  }

  const sd = useLocalizedResource("screen-2b-select-use-cases");
  const sectors = useLocalizedResource("screen-1-select-sector")?.sectors;
  const useCases = useLocalizedResource("screen-2-discover-use-cases");
  const gd = useLocalizedResource("global");
  if (sd == null) return null;
  if (sectors == null) return null;
  if (useCases == null) return null;
  if (gd == null) return null;

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4} sx={{ alignItems: 'center' }}>
        <Grid item xs={0} sm={4} md={3}>
          <Box sx={{
            width: '100%', // Make sure the width is 100%
            pt: '100%', // Padding-top as percentage for responsive square
            position: 'relative', // Position relative for the absolute child
          }}>
            <img
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 4,
              }}
              alt=''
              src={'images/' + sd.headerImage}
            />
          </Box>
        </Grid>

        {  /* Title and Description */}
        <Grid item xs={12} sm={8} md={9}>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h4' dangerouslySetInnerHTML={{ __html: sd.title }}></Typography>
            <Typography variant='h5' dangerouslySetInnerHTML={{ __html: sd.subtitle }} gutterBottom></Typography>
          </Box>
        </Grid>
      </Grid>

      <Typography variant='h5' gutterBottom>{sd.useCasesHeaderPrefix}{sectors.filter(s => s.id === appState.selectedSectorId)[0]?.name}</Typography>
      <Paper elevation={3}>
        <List>
          {useCases.useCases.filter(uc => uc.sectorId === appState.selectedSectorId).flatMap((useCase, itemIndex) => (
            [
              <ListItem key={'uc-item-' + itemIndex} onClick={() => handleUseCaseClicked(useCase.id)} disablePadding>

                <ListItemButton >
                  <ListItemIcon>
                    <Checkbox checked={selectedUseCaseIds.includes(useCase.id)} edge="start" tabIndex={-1} disableRipple />
                  </ListItemIcon>
                  <ListItemText primary={useCase.title} secondary={useCase.summary} />
                </ListItemButton>

              </ListItem>,
              <Divider key={'divider-' + itemIndex}></Divider>
            ]
          ))}
          <ListItem key={'uc-item-custom'}>
            <ListItemIcon>
              <Checkbox checked={!!customUseCase} disabled edge="start" tabIndex={-1} disableRipple />
            </ListItemIcon>
            <TextField variant='standard' size='medium' placeholder={sd.customUseCasePlaceholder} value={customUseCase} onChange={handleCustomUseCaseChange} onBlur={handleCustomUseCaseBlur} fullWidth></TextField>
          </ListItem>
        </List>
      </Paper>
      <Typography variant='h5' mt={5} gutterBottom>{sd.useCasesHeaderPrefix}{
        concatenateWords(sectors.filter(s => s.id !== appState.selectedSectorId).map(s => s.name), gd.text.enumerationSeparator, gd.text.enumerationLastSeparator)}
      </Typography>
      <Paper elevation={3}>
        <List>
          {useCases.useCases.filter(uc => uc.sectorId !== appState.selectedSectorId).flatMap((useCase, itemIndex, list) => (
            [
              <ListItem key={'uc-item-' + itemIndex} onClick={() => handleUseCaseClicked(useCase.id)} disablePadding>

                <ListItemButton >
                  <ListItemIcon>
                    <Checkbox checked={selectedUseCaseIds.includes(useCase.id)} edge="start" tabIndex={-1} disableRipple />
                  </ListItemIcon>
                  <ListItemText primary={useCase.title} secondary={useCase.summary} />
                </ListItemButton>

              </ListItem>,
              ((itemIndex + 2) <= list.length) && (<Divider key={'divider-' + itemIndex}></Divider>)
            ]
          ))}
        </List>
      </Paper>
      <Grid container my={5} rowSpacing={3} sx={{ justifyContent: 'flex-end' }} pb={8}>
        <Grid item xs={12}>
          <Divider variant='fullWidth'></Divider>
        </Grid>
        <Grid item>
          <Button
            sx={{ justifySelf: 'flex-end' }}
            variant="contained"
            size='large'
            disabled={!canContinue()}
            onClick={() => continueToNextPage()}
            endIcon={<ChevronRight />}
          >
            {gd.button.continue}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SelectUserCasesScreen;
