import { createContext } from "react";

export const LanguageContext = createContext();

export function LanguageProvider({ appState, setAppState, children }) {
  const language = appState.language || 'en';
  const setLanguage = (language) => {
    setAppState(appState => ({
      ...appState,
      language: language,
    }))
  };
  const value = { language, setLanguage };
  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
}
