import { createContext, useState } from "react";

export const LoadingContext = createContext();

export function LoadingProvider({ children }) {
    const [isLoading, setLoading] = useState(false);

    const value = {
        isLoading,
        startLoading: () => setLoading(true),
        stopLoading: () => setLoading(false)
    };

    return (
        <LoadingContext.Provider value={value}>
            {children}
        </LoadingContext.Provider>
    );
}
