import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent, CardMedia, Switch, Grid, CardActions, Divider, Container, Button, CardActionArea } from '@mui/material';

import { ChevronRight } from '@mui/icons-material';
import { useLocalizedResource } from '../components/useLocalizedResource';

const calculateRecommendedModules = (selectedProblemIds, problems) => {
  return (selectedProblemIds || []).flatMap(problemId => {
    const problem = (problems).find(p => p.id === problemId);
    return problem ? problem.related_modules : [];
  });
};

export default function ModuleSelectionScreen({ onCanContinueChanged, appState, setAppState, continueToNextPage }) {
  useEffect(() => onCanContinueChanged(true))

  const problems = useLocalizedResource("problems") || []; // eslint-disable-line react-hooks/exhaustive-deps
  const modules = useLocalizedResource("modules") || [];
  const sd = useLocalizedResource("screen-5-module-selection");
  const gd = useLocalizedResource("global");

  const recommendedModuleIds = calculateRecommendedModules(appState.selectedProblemIds, problems)

  const recommendedModules = modules.filter(module => recommendedModuleIds.includes(module.id));
  const additionalModules = modules.filter(module => !recommendedModuleIds.includes(module.id));

  const [selectedModules, setSelectedModules] = useState((appState.selectedModuleIds || []).reduce((prev, curr) => ({ ...prev, [curr]: true }), {}));

  useEffect(() => {
    let recommendedModuleIds = calculateRecommendedModules(appState.selectedProblemIds, problems)
    if (recommendedModuleIds.length && !appState.selectedModuleIds?.length) {
      const newSelection = (recommendedModuleIds).reduce((prev, curr) => ({ ...prev, [curr]: true }), {})
      setSelectedModules(newSelection);
    }
  }, [appState.selectedProblemIds, problems, appState.selectedModuleIds]);

  if (problems == null) return null;
  if (sd == null) return null;
  if (gd == null) return null;

  const saveSelectedModules = (newSelection) => {
    setAppState(appState => ({
      ...appState,
      selectedModuleIds: Object.entries(newSelection).filter(([_, value]) => value).map(([key, _]) => key),
      deselectedModuleIds: Object.entries(newSelection).filter(([_, value]) => !value).map(([key, _]) => key)
    }));
  }

  const handleNextButtonClick = () => {
    saveSelectedModules(selectedModules);
    continueToNextPage();
  }

  const handleToggleModule = (moduleId) => {
    const newSelection = { ...selectedModules, [moduleId]: !selectedModules[moduleId] }
    setSelectedModules(newSelection);
    saveSelectedModules(newSelection)
  };

  return (
    <Container p={3}>
      <Typography variant="h4" gutterBottom>
        {sd.recommendedModules.title}
      </Typography>
      <Typography variant="h5" mb={4}>
        {sd.recommendedModules.subtitle}
      </Typography>

      <Grid container spacing={3} alignItems='stretch'>
        {recommendedModules.map((module, index) => (
          <Grid item xs={12} sm={6} md={4} key={module.id} >
            <Card elevation={selectedModules[module.id] ? 5 : 2} sx={{ height: "100%", display: "flex", flexDirection: 'column' }}>
              <CardActionArea onClick={() => handleToggleModule(module.id)} sx={{ height: "100%", display: "flex", flexDirection: 'column', alignItems: 'stretch' }}>
                <CardMedia
                  component="img"
                  width="100%"
                  src={'images/' + module.image}
                  alt=""
                  sx={{maxWidth: '100%', maxHeight: '10rem', objectFit: 'contain' }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="overline" component="div" color="primary">
                    {module.category}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {module.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {module.description}
                  </Typography>
                </CardContent>
                <Divider />
                <CardActions sx={{ flexDirection: 'row-reverse' }} >
                  <Switch sx={{ justifySelf: "end" }}
                    checked={!!selectedModules[module.id]}
                    name={module.name}
                  />
                </CardActions>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h4" mt={8} mb={1}>
        {sd.otherModules.title}
      </Typography>
      <Typography variant="h5" component="div" mb={4}>
        {sd.otherModules.subtitle}
      </Typography>

      <Grid container spacing={3} alignItems='stretch'>
        {additionalModules.map((module, index) => (
          <Grid key={index} item xs={12} sm={6} md={6}>
            <Card sx={{ height: "100%", display: "flex", flexDirection: 'column' }} elevation={selectedModules[module.id] ? 5 : 2}>
              <CardActionArea onClick={() => handleToggleModule(module.id)} sx={{ height: "100%", display: "flex", flexDirection: 'column', alignItems: 'stretch' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="overline" component="div" color="primary">
                    {module.category}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {module.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {module.description}
                  </Typography>
                </CardContent>
                <Divider></Divider>
                <CardActions sx={{ flexDirection: 'row-reverse' }} >
                  <Switch sx={{ justifySelf: "end" }}
                    checked={!!selectedModules[module.id]}
                    name={module.name}
                  />
                </CardActions>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid container my={5} rowSpacing={3} sx={{ justifyContent: 'flex-end' }} pb={8}>
        <Grid item xs={12}>
          <Divider variant='fullWidth'></Divider>
        </Grid>
        <Grid item>
          <Button
            sx={{ justifySelf: 'flex-end' }}
            variant="contained"
            size='large'
            onClick={() => handleNextButtonClick()}
            endIcon={<ChevronRight />}
          >
            {gd.button.continue}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
