import React from 'react';

import { Box, Button, Card, CardActions, CardContent, Container, Divider, Grid, Switch } from "@mui/material";
import { Typography } from '@mui/material';

import { ChevronRight } from '@mui/icons-material';
import { useLocalizedResource } from '../components/useLocalizedResource';

export default function Screen6BatteriesIncluded({ onCanContinueChanged, appState, setAppState, continueToNextPage }) {

    const sd = useLocalizedResource("screen-6-batteries-included");
    const gd = useLocalizedResource("global");
    if (sd == null) return null;
    if (gd == null) return null;

    return (
        <Container>

            <Grid container spacing={4} sx={{ alignItems: 'center' }}>
                {/* Image */} <Grid item xs={0} sm={4} md={3}>
                    <Box sx={{
                        width: '100%', // Make sure the width is 100%
                        pt: '100%', // Padding-top as percentage for responsive square
                        position: 'relative', // Position relative for the absolute child
                    }}>
                        <img
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: 4,
                            }}
                            alt=''
                            src={'images/' + sd.headerImage}
                        />
                    </Box>
                </Grid>
                {  /* Title and Description */}
                <Grid item xs={12} sm={8} md={9}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant='h4' dangerouslySetInnerHTML={{ __html: sd.title }}></Typography>
                        <Typography variant='h5' dangerouslySetInnerHTML={{ __html: sd.subtitle }}></Typography>
                    </Box>
                </Grid>
            </Grid>

            {/* Requirements lists */}
            <Grid container rowSpacing={2} mt={3}>
                {sd.requirements.map((requirement, index) => (
                    <Grid key={'req-' + index} item>

                        <Box p={2} pt={4}>
                            <Typography variant="h4" gutterBottom>
                                {requirement.title}
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                {requirement.subtitle}
                            </Typography>
                        </Box>

                        <Grid container spacing={3} alignItems='stretch'>
                            {requirement.items.map((item, index) => (
                                <Grid key={index} item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: "100%", display: "flex", flexDirection: 'column' }} elevation={5}>
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {item.name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {item.description}
                                            </Typography>
                                        </CardContent>
                                        <Divider></Divider>
                                        <CardActions sx={{ flexDirection: 'row-reverse' }} >
                                            <Switch sx={{ justifySelf: "end" }}
                                                color='primary'
                                                checked={true}
                                                disabled
                                                name={module.title}
                                            />
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                    </Grid>
                ))}
            </Grid>

            <Grid container my={5} rowSpacing={3} sx={{ justifyContent: 'flex-end' }} pb={8}>
                <Grid item xs={12}>
                    <Divider variant='fullWidth'></Divider>
                </Grid>
                <Grid item>
                    <Button
                        sx={{ justifySelf: 'flex-end' }}
                        variant="contained"
                        size='large'
                        onClick={() => continueToNextPage()}
                        endIcon={<ChevronRight />}
                    >
                        {gd.button.continue}
                    </Button>
                </Grid>
            </Grid>
        </Container>);
}
