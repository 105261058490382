import React, { useEffect } from 'react';
import { Box, Typography, Checkbox, Container, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Paper, Grid, Divider, Button } from '@mui/material';

import groupBy from 'lodash.groupby'

import { ChevronRight } from '@mui/icons-material';
import { useLocalizedResource } from '../components/useLocalizedResource';


const categories = [];
var problems = [];

problems.forEach(p => {
  let cat = categories.find(c => c.title === p.category)
  if (!cat) {
    cat = {
      title: p.category,
      image: p.category_image,
      items: [],
    }
    categories.push(cat)
  }

  cat.items.push(p.description)
})


export default function ValuePropositionForm({ onCanContinueChanged, appState, setAppState, continueToNextPage }) {

  const canContinue = () => {
    return appState.selectedProblemIds?.length
  }

  useEffect(() => onCanContinueChanged(canContinue()))

  const handleCheckboxChange = (problemId) => {
    setAppState(appState => {
      const currentProblemIds = appState.selectedProblemIds || [];
      const wasRemoved = currentProblemIds.includes(problemId);
      const newSelectedProblemIds = wasRemoved
        ? currentProblemIds.filter(pid => pid !== problemId)
        : [...currentProblemIds, problemId]
      const newSelectedProblems = problems.filter(p => newSelectedProblemIds.includes(p.id)).map(p => p.description)
      const newDeselectedProblemIds = wasRemoved
        ? [...(appState.deselectedProblemIds || []), problemId]
        : appState.deselectedProblemIds;

      return {
        ...appState,
        selectedModuleIds: undefined, // start fresh if navigated back
        selectedProblemIds: newSelectedProblemIds,
        deselectedProblemIds: newDeselectedProblemIds,
        selectedProblems: newSelectedProblems,
      }
    });
  };

  const sd = useLocalizedResource("screen-4-value-props");
  const gd = useLocalizedResource("global");
  problems = useLocalizedResource("problems");
  if (sd == null) return null;
  if (problems == null) return null;
  if (gd == null) return null;

  return (
    <Container>

      <Box sx={{ alignSelf: 'stretch', display: 'flex', alignItems: 'flex-start', gap: 4 }}>
        {/* Title and Description */}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant='h4' dangerouslySetInnerHTML={{ __html: sd.title }}></Typography>
          <Typography variant='h5' dangerouslySetInnerHTML={{ __html: sd.subtitle }} gutterBottom></Typography>
        </Box>
      </Box>

      <Typography variant='h5' mt={7} mb={4}>
        {sd.cta}
      </Typography>

      {/* Selections */}

      <Grid container rowSpacing={3} spacing={3} sx={{ alignItems: 'flex-start' }}>
        {/* Lists for each category */}
        {Object.entries(groupBy(problems, p => p.category)).flatMap(([category, problems], index) =>
          [
            /* Image */
            <Grid key={'img-' + index} item xs={0} sm={4} md={3}>
              <Box sx={{
                width: '100%', // Make sure the width is 100%
                pt: '100%', // Padding-top as percentage for responsive square
                position: 'relative', // Position relative for the absolute child
              }}>
                <img
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: 4,
                  }}
                  alt=''
                  src={'images/' + problems[0].category_image}
                />
              </Box>
            </Grid>,

            /* Title and Description */
            <Grid key={'list-' + index} item xs={12} sm={8} md={9}>
              <Paper>
                <Typography variant='h6' p={2}>
                  {problems[0].category}
                </Typography>
                <Divider></Divider>
                <List key={index} dense>
                  {problems.map((problem, itemIndex) => {
                    const key = `${problem.category}-${itemIndex}`
                    return (<ListItem key={key}
                      disablePadding
                      divider={itemIndex <= (problems.length - 2)}>
                      <ListItemButton role='undefined' onClick={() => handleCheckboxChange(problem.id)} >
                        <ListItemIcon >
                          <Checkbox
                            edge="start"
                            name={key}
                            checked={!!appState.selectedProblemIds?.includes(problem.id)}

                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': key }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={problem.description} id={key} />
                      </ListItemButton>
                    </ListItem>)
                  })}
                </List>
              </Paper>
            </Grid>
          ] // end flatMap value
        )}
      </Grid>
      <Grid container my={5} rowSpacing={3} sx={{ justifyContent: 'flex-end' }} pb={8}>
        <Grid item xs={12}>
          <Divider variant='fullWidth'></Divider>
        </Grid>
        <Grid item>
          <Button
            sx={{ justifySelf: 'flex-end' }}
            variant="contained"
            size='large'
            disabled={!canContinue()}
            onClick={() => continueToNextPage()}
            endIcon={<ChevronRight />}
          >
            {gd.button.continue}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
