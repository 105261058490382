import React, { useState } from 'react';
import { Box, Button, Container, Card, InputAdornment, CardContent, CardMedia, Divider, Grid, Typography, Chip, TextField, CardActions, FormControlLabel, Link, Checkbox } from '@mui/material';

import emailProviders from 'email-providers/all.json';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useLocalizedResource } from '../components/useLocalizedResource';

const Screen7ResultsAndContact = ({ continueToNextPage, appState, setAppState }) => {
    // email handling
    const [email, setEmail] = useState(appState.email || '');
    const [acceptedDataProcessing, setAcceptedDataProcessing] = useState(appState.dataProcessingAccepted);
    const [emailError, setEmailError] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [emailPristine, setEmailPristine] = useState(true)

    // message form
    const sd = useLocalizedResource("screen-7-result-and-contact");
    const ed = useLocalizedResource("screen-3-email-form");
    const modules = useLocalizedResource("modules");

    const [billingMode, setBillingMode] = useState(appState.selectedBillingMode || 'monthly');
    const [name, setName] = useState(appState.userName || '');
    const [nameError, setNameError] = useState(sd?.formError.nameEmpty);
    const [namePristine, setNamePristine] = useState(true)
    const [message, setMessage] = useState(appState.userMessage || '');
    const [messageError, setMessageError] = useState(sd?.formError.messageEmpty);
    const [messagePristine, setMessagePristine] = useState(true)


    if (sd == null) return null;
    if (ed == null) return null;
    if (modules == null) return null;

    const isValidName = (name) => {
        return name.length > 2
    };

    const isValidMessage = (message) => {
        return message.trim().split(' ').length > 2 // expect 3 words
    };

    const handleNameChange = (event) => {
        const newName = event.target.value || '';
        setName(newName);

        if (isValidName(newName)) {
            setNameError('');
            setAppState(appState => ({
                ...appState,
                userName: newName,
            }));
        } else {
            setNameError(sd.formError.nameEmpty);
        }
    };

    const handleMessageChange = (event) => {
        const newMessage = event.target.value || '';
        setMessage(newMessage);

        if (isValidMessage(newMessage)) {
            setMessageError('');
            setAppState(appState => ({
                ...appState,
                userMessage: newMessage
            }));
        } else {
            setMessageError(sd.formError.messageEmpty);
        }
    };

    const handleBillingModeChange = (event) => {
        const selectedBillingMode = event.target.checked ? 'monthly' : 'payOnce';
        setBillingMode(selectedBillingMode);

        setAppState(appState => ({
            ...appState,
            selectedBillingMode,
        }));
    }

    const handleNameBlur = () => {
        setNamePristine(false)
    }

    const handleMessageBlur = () => {
        setMessagePristine(false)
    }

    const canSubmit = () => {
        return (isValidMessage(message)) && (isValidName(name)) && (acceptedDataProcessing && (!emailError && !!email));
    }

    function uniqueFilter(value, index, self) {
        return self.indexOf(value) === index;
    }

    const selectedModuleIds = appState.selectedModuleIds || [];
    const coveredCategories = modules.filter(m => selectedModuleIds.includes(m.id))
        .map(m => m.category).filter(uniqueFilter)


    const calculatePricingPackage = () => {
        let packageNumber = 1;
        if (selectedModuleIds.length >= 6) {
            packageNumber = 2;
        }
        if (selectedModuleIds.length >= 12) {
            packageNumber = 3;
        }
        if (selectedModuleIds.includes('hardware-integration')) {
            packageNumber = Math.min(3, packageNumber + 1);
        }

        const mapNumberToPackageName = {
            1: "S",
            2: "M",
            3: "L"
        };

        return sd.productCard.package[mapNumberToPackageName[packageNumber]];
    }

    const applicablePackage = calculatePricingPackage();

    const handleSubmit = () => {
        setAppState(appState => ({
            ...appState,
            displayedPricingPackage: applicablePackage,
        }));
        continueToNextPage()
    };

    // email handling


    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isValidEmailFormat = (email) => {
        return emailRegex.test(email);
    };

    const handleEmailChange = (event) => {
        const newEmail = event.target.value?.trim();
        setAcceptedDataProcessing(false);
        setEmail(newEmail);


        if (isValidEmailFormat(newEmail)) {
            const domain = newEmail.split('@')[1];
            if (emailProviders.includes(domain)) {
                setEmailError(ed.errorOnlyBusinessEmailAllowed);
                setIsValidEmail(false);
            } else {
                setEmailError('');
                setIsValidEmail(true);
                setAppState(appState => ({
                    ...appState,
                    email: newEmail,
                }));
            }
        } else {
            setEmailError(ed.errorEmailInvalid);
            setIsValidEmail(false);
        }
    };

    const handleEmailBlur = (event) => {
        setEmailPristine(false)
    }

    const handleDataProcessingConsent = (event) => {
        setAcceptedDataProcessing(event.target.checked)
        setAppState(appState => ({
            ...appState,
            dataProcessingAccepted: event.target.checked,
            dataProcessingAcceptedAt: new Date().toUTCString()
        }));
    }

    return (
        <div>
            <Container>
                <Grid container sx={{ padding: 0 }}>
                    <Box pb={4}>
                        <Grid container alignItems='start' justifyContent='center' columnSpacing={8}>
                            <Grid item xs={12} sm={10} md={6} lg={5} container alignItems='center' mb={8}>

                                <Card raised sx={{ borderRadius: '2rem' }}>
                                    <CardContent sx={{ padding: '2rem' }}>
                                        <Grid>
                                            <Grid item>
                                                <Typography variant='h4' align='center' pb={2}>
                                                    {sd.productCard.title}
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item container py={3} flexDirection={'row'} justifyContent={'center'} alignItems='center'>
                                                <Grid item xs={"auto"} >
                                                    <Typography variant='body1' fontWeight={billingMode === 'monthly' ? 400 : 700} fontSize={'20px'} >{sd.productCard.billingMode.payOnce}</Typography>

                                                </Grid>
                                                <Grid item xs="auto"  >
                                                    <Switch checked={billingMode === 'monthly'} onChange={handleBillingModeChange} />
                                                </Grid>
                                                <Grid item xs="auto" >
                                                    <Typography variant='body1' fontWeight={billingMode !== 'monthly' ? 400 : 700} fontSize={'20px'} >{sd.productCard.billingMode.monthly}</Typography>
                                                </Grid>
                                            </Grid> */}
                                            <Grid item>
                                                <Box backgroundColor='#F9F9F9' borderRadius={2} p={3}>
                                                    <Grid container direction={'column'} alignItems={'center'}>
                                                        <Typography variant='productMainNumber'>{applicablePackage.cost[billingMode]}</Typography>
                                                        <Typography variant='productMainNumberSubtext1'>{sd.productCard.billingModeInfo[billingMode].line1}</Typography>
                                                        <Typography variant='productMainNumberSubtext2'>{sd.productCard.billingModeInfo[billingMode].line2}</Typography>
                                                    </Grid>
                                                </Box>
                                                <Box backgroundColor='#F9F9F9' borderRadius={2} p={3} mt={1}>
                                                    <Grid container direction={'column'} alignItems={'center'}>
                                                        <Typography variant='productMainNumber'>{applicablePackage.typicalProjectDuration}</Typography>
                                                        <Typography variant='productMainNumberSubtext1'>{sd.productCard.typicalProjectDurationLabel}</Typography>

                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item container pt={3} gap={1} justifyContent='center'>

                                                {
                                                    sd.productCard.highlightedIncludedFeatures.map(feat => {
                                                        return (<Chip key={feat} size='medium' variant='filled' icon={<CheckCircleIcon color='secondary' />} label={feat}></Chip>)
                                                    })
                                                }

                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Typography mt={2} variant="body2" color="text.secondary" align='center' sx={{ display: 'block' }}>
                                    {sd.productCard.disclaimer}
                                </Typography>

                            </Grid>

                            {/* Title and Description */}
                            <Grid item container alignSelf='center' sm={12} md={6} lg={5} my={0} gap={4}>
                                <Grid item>
                                    <Typography variant='h4' dangerouslySetInnerHTML={{ __html: sd.title }}></Typography>
                                    <Typography variant='h5' dangerouslySetInnerHTML={{ __html: sd.subtitle }}></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={sd.formLabel.name}
                                        value={name}
                                        onChange={handleNameChange}
                                        onBlur={handleNameBlur}
                                        variant="outlined"
                                        type='text'
                                        size='medium'
                                        sx={{
                                            backgroundColor: "white"
                                        }}

                                        fullWidth
                                        error={!namePristine && !!nameError}
                                        helperText={namePristine ? '' : nameError}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {namePristine ? '' : !nameError ? (
                                                        <CheckCircleIcon color="success" />
                                                    ) : (
                                                        <ErrorOutlineIcon color="error" />
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        multiline
                                        minRows={3}
                                        label={sd.formLabel.message}
                                        value={message}
                                        sx={{
                                            backgroundColor: "white"
                                        }}

                                        onChange={handleMessageChange}
                                        onBlur={handleMessageBlur}
                                        variant="outlined"
                                        type='text'
                                        size='medium'

                                        fullWidth
                                        error={!messagePristine && !!messageError}
                                        helperText={messagePristine ? '' : messageError}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {messagePristine ? '' : !messageError ? (
                                                        <CheckCircleIcon color="success" />
                                                    ) : (
                                                        <ErrorOutlineIcon color="error" />
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid>
                                    <TextField
                                        label={ed.labelEmailInput}
                                        value={email}
                                        onChange={handleEmailChange}
                                        onBlur={handleEmailBlur}
                                        variant="outlined"
                                        size='medium'
                                        type='email'
                                        fullWidth
                                        error={!emailPristine && !!emailError}
                                        helperText={emailPristine ? '' : emailError}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {emailPristine ? '' : isValidEmail ? (
                                                        <CheckCircleIcon color="success" />
                                                    ) : email && (
                                                        <ErrorOutlineIcon color="error" />
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            backgroundColor: "white"
                                        }}
                                    />

                                    <FormControlLabel
                                        checked={acceptedDataProcessing || false}
                                        control={<Checkbox onChange={handleDataProcessingConsent} />}
                                        label={<Typography>{ed.dataProtection.textStart} <Link href={ed.dataProtection.link.url} target='_blank'>{ed.dataProtection.link.text}</Link> {ed.dataProtection.textEnd}</Typography>}
                                        sx={{ '& .MuiSvgIcon-root': { color: '#0288D1' } }}
                                    />

                                    <Typography variant='body2'>{ed.dataUsageHint}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size='large'
                                        onClick={handleSubmit}
                                        sx={{ alignSelf: 'baseline' }}
                                        endIcon={<ChevronRight />}
                                        disabled={!canSubmit()}
                                    >
                                        {sd.formLabel.submit}
                                    </Button>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Box>


                </Grid>
            </Container>
            <Box sx={{ backgroundColor: "white" }} py={4} >
                <Container>
                    <Grid container >

                        <Grid item>
                            <Typography variant='h4' gutterBottom>
                                {sd.additionalServicesTitle}
                            </Typography>
                            <Typography variant='h5'>
                                {sd.additionalServicesText}
                            </Typography>
                        </Grid>

                        {/* Cards for modules */}
                        <Grid container spacing={4} mt={0} alignItems='stretch'>
                            {sd.additionalServices.map((service) => (
                                <Grid item xs={12} sm={6} md={4} key={service.title} >
                                    <Card elevation={5} sx={{ height: "100%", display: "flex", flexDirection: 'column' }}>
                                        <CardMedia
                                            component="img"
                                            width="100%"
                                            src={'images/' + service.image}
                                            alt=""
                                        />
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography gutterBottom variant="overline" component="div" color="primary">
                                                {service.category}
                                            </Typography>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {service.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {service.description}
                                            </Typography>
                                        </CardContent>
                                        <Divider />
                                        <CardActions sx={{ flexDirection: 'row-reverse' }}>
                                            <Typography mr={1} variant="h6" color="text.primary">
                                                {service.price}
                                            </Typography>
                                        </CardActions>

                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                    </Grid>
                </Container>
            </Box>
        </div>
    );
};

export default Screen7ResultsAndContact;
