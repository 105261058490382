import { useContext, useEffect, useState } from "react";
import { LoadingContext } from "./LoadingProvider";
import { LanguageContext } from "./LanguageProvider";

export function useLocalizedResource(resourceName) {
    const { language } = useContext(LanguageContext);
    const { startLoading, stopLoading } = useContext(LoadingContext);
    const [resource, setResource] = useState(null);

    useEffect(() => {
        async function loadResource() {
            startLoading();
            const res = await import(`../content/${resourceName}.${language}.json`);
            setResource(res.default);
            stopLoading();
        }

        loadResource();
    }, [language, startLoading, stopLoading, resourceName]);

    return resource;
}
