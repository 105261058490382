import React, { useEffect } from 'react';
import {
  Container, Typography, Grid, Box, Button, Divider
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useLocalizedResource } from '../components/useLocalizedResource';

function SelectionScreen({ onCanContinueChanged, appState, setAppState, continueToNextPage }) {
  useEffect(() => onCanContinueChanged(true))

  const gd = useLocalizedResource("global");
  const sd = useLocalizedResource("screen-2-discover-use-cases");
  const sd1 = useLocalizedResource("screen-1-select-sector");
  if (gd == null) return null;
  if (sd == null) return null;
  if (sd1 == null) return null;

  const sectorTitle = sd1.sectors.filter(s => s.id === appState.selectedSectorId)[0]?.name;

  return (
    <Container maxWidth="lg">
      {/* Heading content with Image */}

      <Box>
        <Typography variant='h4'>{sectorTitle}{sectorTitle ? ' — ' : ''}<span dangerouslySetInnerHTML={{ __html: sd.title }}></span></Typography>
        <Typography variant='h5' dangerouslySetInnerHTML={{ __html: sd.subtitle }} pt={1}></Typography>
      </Box>

      {/* Cards */}
      <Grid container mt={4} gap={8} alignItems="flex-start">
        {sd.useCases.filter(uc => uc.sectorId === appState.selectedSectorId).map((useCase, index) => (
          <Grid item xs={12} key={useCase.title}>
            <Box sx={{
              width: {
                xs: '100%',
                sm: '40%',
                md: '30%',
              },
              float: {
                xs: 'none',
                sm: index % 2 === 0 ? 'left' : 'right',
              },
              marginRight: index % 2 === 0 ? '1rem' : 0,
              marginLeft: index % 2 === 0 ? 0 : '1rem',
              display: {
                xs: 'none',
                sm: 'block',
              }
            }} >
              <img src={'images/' + useCase.image} style={{ width: '100%' }} alt='' />
            </Box>

            <Typography variant="overline" color="primary" gutterBottom sx={{ wordBreak: 'break-word' }}>
              {sd.useCaseOverlinePrefix} {useCase.category}
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ wordBreak: 'break-word' }}>
              {useCase.title}
            </Typography>
            <Typography variant="body2" dangerouslySetInnerHTML={
              {
                __html: useCase.article_text
              }
            }>

            </Typography>

          </Grid>
        ))}
      </Grid>
      <Grid container my={5} rowSpacing={3} sx={{ justifyContent: 'flex-end' }} pb={8}>
        <Grid item xs={12}>
          <Divider variant='fullWidth'></Divider>
        </Grid>
        <Grid item>
          <Button
            sx={{ justifySelf: 'flex-end' }}
            variant="contained"
            size='large'
            onClick={() => continueToNextPage()}
            endIcon={<ChevronRight />}
          >
            {gd.button.continue}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SelectionScreen;
