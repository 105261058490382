import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import debounce from 'lodash.debounce'

import React, { useEffect, useState } from 'react';
import { Box, Slide, useScrollTrigger, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

import logo from './logo.svg';
import Screen1 from './screens/screen-1-select-sector';
import Screen2 from './screens/screen-2-discover-use-cases';
import Screen2b from './screens/screen-2b-select-use-cases';
import Screen4 from './screens/screen-4-value-props';
import Screen5 from './screens/screen-5-module-selection';
import Screen6 from './screens/screen-6-batteries-included';
import Screen7 from './screens/screen-7-result-and-contact';
import Screen8 from './screens/screen-8-finished-thank-you';
import './App.css';


import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { LoadingProvider } from './components/LoadingProvider';
import { LanguageProvider } from './components/LanguageProvider';
import CustomAppBar from './components/CustomAppBar';


let theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    allVariants: true,
    fontWeightBold: true,
    fontWeightMedium: true,
    productMainNumber: {
      lineHeight: 1.2,
      fontWeight: 300,
      fontSize: '3.4rem',
      fontFamily: 'Poppins',
      textAlign: 'center',
    },
    productMainNumberSubtext1: {
      lineHeight: 1.4,
      fontWeight: 400,
      fontSize: '1.2rem',
      color: 'rgba(0, 0, 0, 0.60)',
      fontFamily: 'Poppins',
      textAlign: 'center',
    },
    productMainNumberSubtext2: {
      lineHeight: 1.4,
      fontWeight: 400,
      fontSize: '1.2rem',
      color: 'rgba(0, 0, 0, 0.38)',
      fontFamily: 'Poppins',
      textAlign: 'center',
    },
    langSwitchLink: {
      lineHeight: 1,
      fontSize: '1.5rem',
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
      color: 'white',
    },
    h4: {
      fontWeight: 600,
      letterSpacing: '0.025rem'
    },
    h5: {
      fontWeight: 400,
    },
    h6: {
      fontWeight: 700,
      fontFamily: 'Open Sans',
      lineHeight: 1.6, // Assuming the root font size is 20px, 32px becomes 1.6
      fontSize: '1.25rem', // Assuming the root font size is 16px, 20px becomes 1.25rem
      letterSpacing: '0.015rem' // Converted to em
    },
    overline: {
      fontSize: '0.875rem', // Assuming the root font size is 16px, 14px becomes 0.875rem
      fontFamily: 'Open Sans',
      fontWeight: 600,
      lineHeight: 1.37, // Assuming the root font size is 16px, 21.98px becomes roughly 1.37
      letterSpacing: '0.01rem',
      wordWrap: 'break-word',
      textTransform: 'auto'
    },
    body2: {
      fontFamily: 'Open Sans',
      fontSize: '1rem', // Assuming the root font size is 16px, 16px becomes 1rem
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.60)'
    }
  },
  palette: {
    primary: {
      main: '#21C8FF', // This should be the color of your primary button and active elements.
      dark: '#00BBFF',
      light: '73D7FF',
      contrastText: 'white',
    },
    secondary: {
      main: '#00E3C3', // Choose an appropriate secondary color if needed.
      dark: '#00D3AC',
      light: '#93EEDC',
      contrastText: 'white',
    },
    background: {
      default: '#FFFFFF', // This is typically the background color for the content area.
      paper: '#FFFFFF', // Use the color of your card backgrounds here.
    },
    text: {
      primary: '#000000DE', // This is the color for primary text.
      secondary: '#00000099', // This is for secondary text which is less prominent than primary text.
    },
    action: {
      active: 'rgba(0, 0, 0, 0.56)', // This can be the color for icons or other active elements.
      disabledBackground: 'rgba(128, 128, 128, .5)'
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 2
      }
    },
    MuiTypography: {
      styleOverrides: {
        overline: {
          root: {
          },
          display: 'inline-block',
        },
        gutterBottom: {
          marginBottom: '1rem'
        }
      }
    },
    // customize components
    MuiButton: {
      styleOverrides: {
        root: {
          // for specific styles for buttons, for example
        },
        sizeLarge: {
          fontWeight: 700,
        },
        sizeSmall: {
          fontFamily: 'Roboto',
          fontWeight: 500,
        }
      },
    },

  },
});

theme = responsiveFontSizes(theme, {
  factor: 2,
  variants: Object.keys(theme.typography)
    .filter(variant => { return (+theme.typography[variant].lineHeight) > 0 })
});

function debugLog(...messages) {
  if (process.env.NODE_ENV === 'development') {
    console.log(...messages);
  }
}


const postData = async (url = '', data = {}) => {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

const sendAppState = (appState) => {
  if (!appState.email) {
    debugLog("not sending app state, no email specified.")
    return;
  }
  if (!appState.dataProcessingAccepted) {
    debugLog("not sending app state, dataProcessingAccepted not set.")
    return;
  }

  const postBody = { dtxConfig: appState }
  debugLog("sending app state to backend: " + JSON.stringify(postBody))
  postData('/saveConfiguration', postBody)
    .then(data => {
      console.log(data); // JSON data parsed by `response.json()` call
    })
    .catch(error => {
      console.error('Error:', error);
    });
};

const sendAppStateDebounced = debounce((appState) => {
  return sendAppState(appState);
}, 1000);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'instant', // for smooth scrolling
  });
};

function HideOnScroll({ children }) {
  const isWidthDownLG = useMediaQuery(theme.breakpoints.down('md'));

  const trigger = useScrollTrigger({});

  if (isWidthDownLG) {
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  else {
    return children;
  }
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};


function App() {
  useEffect(() => {
    const cssStyle = 'color: rgb(33, 200, 255); font-size: 20px; font-weight: bold;';
    console.log('%cWelcome to our Online Configurator of the DTx Toolkit!', cssStyle);
    console.log('Looking for a healthy job? We\'re hiring! Check https://fluidmobile.de/jobs/?utm_source=dtx_configurator for details.');
  }, []);



  const loadState = () => {
    const savedState = localStorage.getItem('appState');
    if (savedState) {
      debugLog("loaded state", savedState)
      return JSON.parse(savedState);
    } else {
      debugLog("did not find existing state")
      return {
        sessionId: new Date().toISOString(),
        email: process.env.REACT_APP_PREFILL_EMAIL,
        currentScreenIndex: 0,
        visitedPages: [0],
      };
    }
  };

  const [appState, setAppState] = useState(loadState());

  // Function to save state to local storage
  const saveState = (state) => {
    localStorage.setItem('appState', JSON.stringify(state));
  };

  const goForward = () => {
    handleAppStateChange(appState => ({
      ...appState,
      currentScreenIndex: Math.min(appState.currentScreenIndex + 1, screens.length - 1),
      visitedPages: [...appState.visitedPages, Math.min(appState.currentScreenIndex + 1, screens.length - 1)]
    }));
    scrollToTop()
  };

  const startOver = () => {
    handleAppStateChange(appState => ({
      sessionId: new Date().toISOString(),
      currentScreenIndex: 0,
      language: appState.language,
      visitedPages: [0],
      email: appState.email,
      userName: appState.userName,
      userMessage: appState.userMessage,
      dataProcessingAccepted: appState.dataProcessingAccepted,
      dataProcessingAcceptedAt: appState.dataProcessingAcceptedAt,
    }));
    localStorage.removeItem('appState'); // Clear local storage
  };

  const handleAppStateChange = (reducer) => {
    setAppState(appState => {
      debugLog("app state before", appState);
      const newState = reducer(appState);
      saveState(newState); // Save to local storage
      sendAppStateDebounced(newState);
      return newState;
    });
  }

  // An array of screen components
  const screens = [
    <Screen1 appState={appState} setAppState={handleAppStateChange} onCanContinueChanged={(_) => { }} continueToNextPage={goForward} />,
    <Screen2 appState={appState} setAppState={handleAppStateChange} onCanContinueChanged={(_) => { }} continueToNextPage={goForward} />,
    <Screen2b appState={appState} setAppState={handleAppStateChange} onCanContinueChanged={(_) => { }} continueToNextPage={goForward} />,
    <Screen4 appState={appState} setAppState={handleAppStateChange} onCanContinueChanged={(_) => { }} continueToNextPage={goForward} />,
    <Screen5 appState={appState} setAppState={handleAppStateChange} onCanContinueChanged={(_) => { }} continueToNextPage={goForward} />,
    <Screen6 appState={appState} setAppState={handleAppStateChange} onCanContinueChanged={(_) => { }} continueToNextPage={goForward} />,
    <Screen7 appState={appState} setAppState={handleAppStateChange} onCanContinueChanged={(_) => { }} continueToNextPage={goForward} />,
    <Screen8 appState={appState} setAppState={handleAppStateChange} onCanContinueChanged={(_) => { }} startOver={startOver} />,
  ];


  // Calculate the progress based on the current screen index
  const progress = ((appState.currentScreenIndex + 1) / screens.length) * 100;

  const goBack = () => {
    handleAppStateChange(appState => ({
      ...appState,
      visitedPages: [...appState.visitedPages, Math.max(appState.currentScreenIndex - 1, 0)],
      currentScreenIndex: Math.max(appState.currentScreenIndex - 1, 0),
    }));
    scrollToTop()
  };


  return (
    <LoadingProvider>
      <LanguageProvider appState={appState} setAppState={handleAppStateChange}>
        <ThemeProvider theme={theme}>
          <Box sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
            {/* AppBar for the header */}
            <HideOnScroll>
              <CustomAppBar logo={logo} goBack={goBack} screens={screens} progress={progress} currentScreenIndex={appState.currentScreenIndex} theme={theme} />
            </HideOnScroll>

            {/* Screen component */}
            <Box sx={{
              flexGrow: 1,
              paddingTop: {
                xs: 2,
                sm: 3,
                md: 5,
                lg: 5,
              },
              backgroundColor: '#FAFAFA'
            }}>
              {screens[appState.currentScreenIndex]}
            </Box>
          </Box>
        </ThemeProvider>
      </LanguageProvider>
    </LoadingProvider>
  );
}

export default App;
