import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Card, CardMedia, CardContent, CardActions, Button, CardActionArea } from '@mui/material';
import { useLocalizedResource } from '../components/useLocalizedResource';

function SelectionScreen({ continueToNextPage, onCanContinueChanged, appState, setAppState }) {
  const [selectedSectorId, setSelectedSectorId] = useState(null);
  
  const handleSectorSelection = (sectorId) => {
    setSelectedSectorId(sectorId);
    setAppState(appState => ({
      ...appState,
      selectedSectorId: sectorId
    }));
    continueToNextPage();
  }
  
  useEffect(() => onCanContinueChanged(true))
  
  const sd = useLocalizedResource("screen-1-select-sector");
  if (sd == null) return null;

  return (
    <Container>
      <Typography variant='h4' dangerouslySetInnerHTML={{ __html: sd.title }} gutterBottom></Typography>
      <Typography variant='h5' dangerouslySetInnerHTML={{ __html: sd.subtitle }} gutterBottom></Typography>
      <Typography variant="h5" gutterBottom pt={6} pb={2}>
        {sd.sectorHeading}
      </Typography>

      <Grid container spacing={4} pb={8}>
        {sd.sectors.map((sector) => (
          <Grid item xs={12} sm={6} md={3} key={sector.name}>
            <Card sx={{ height: "100%", display: "flex", flexDirection: 'column' }} elevation={sector.id === selectedSectorId ? 5 : 2}>
              <CardActionArea component='div' onClick={() => { handleSectorSelection(sector.id) }} sx={{ height: "100%", display: "flex", flexDirection: 'column', alignItems: 'stretch' }}>
                <CardMedia src={'images/' + sector.image} sx={{ maxWidth: '100%', maxHeight: '15rem', objectFit: 'contain' }} component='img'>
                </CardMedia>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6">
                    {sector.name}
                  </Typography>
                  <Typography variant="body2">
                    {sector.description}
                  </Typography>
                </CardContent>
                <CardActions sx={{ flexDirection: 'row-reverse' }}>
                  <Button fullWidth size="large" color="primary" state="enabled" variant='contained' >{sd.selectButtonLabel}</Button>
                </CardActions>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

    </Container>
  );
}

export default SelectionScreen;
